@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.imagePopup {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.1s;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0.85);
    cursor: pointer;
  }

  .popupWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 662px;
    height: 417px;

    @include screen('mobile') {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
    }

    .image {
      position: relative;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }

    .closeBtn {
      position: absolute;
      left: 0;
      top: -42px;
      width: 28px;
      height: 28px;
      border-radius: 2px;
      background-color: rgba($color: #fff, $alpha: 0.2);
      cursor: pointer;
      @include screen('mobile') {
        top: -50px;
        left: 11px;
      }
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 2px;
        height: 18px;
        top: 50%;
        left: 50%;
        background-color: #fff;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}
