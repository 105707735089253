@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.formSection {
  position: relative;
  height: 1360px;
  z-index: 2;
  background-size: cover;
  background-position: 50% 50%;
  width: 100vw;
  display: flex;
  padding-top: 54px;
  border-top: solid 2px rgba(255, 255, 255, 0.4);

  @include screen('mobile') {
    height: 1330px;
    align-items: center;
    border-top: none;
  }

  .form {
    position: relative;
    z-index: 1;
    margin: 0 auto;

    @include screen('mobile') {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    iframe {
      display: block;
      height: 100%;
      width: 100vw;
      border: none;
    }
  }
}
