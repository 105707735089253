@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.list-wrapper {
  min-height: 50px;
  position: relative;
  z-index: 2;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 90px;

  @include screen('mobile') {
    margin-bottom: 90px;
    margin-top: 30px;
  }

  .scroll-point {
    position: absolute;
    z-index: -1;
    bottom: 200px;

    @include screen('small-mobile') {
      bottom: 100px;
    }
  }

  .results-amount-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
    width: 1472px;
    padding-bottom: 16px;

    @include screen('small-desktop') {
      width: 1101px;
    }

    @include screen('tablet') {
      width: 730px;
    }

    @include screen('mobile') {
      width: calc(100% - 17px * 2);
      margin: 0 auto;
    }

    .results-amount {
      width: fit-content;
      height: 35px;
      padding: 0 8px;
      background-color: #fff;
      border-radius: 6px;
      font-size: 21px;
      line-height: 35px;
      color: $dark-blue;
      font-family: $bold-font;

      &.no-results {
        height: 45px;
        font-size: 27px;
        line-height: 45px;
        padding: 0 12px;
        border-radius: 12px;

        @include screen('mobile') {
          height: 36px;
          font-size: 18px;
          line-height: 36px;
          padding: 0 10px;
          border-radius: 10px;
        }
      }

      @include screen('mobile') {
        background-color: #fff;
        height: 30px;
        padding: 0 8px;
        font-size: 16px;
        line-height: 30px;
        border-radius: 6px;
      }
    }

    .search-params {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;

      div {
        height: 35px;
        font-family: $medium-font;
        font-size: 21px;
        line-height: 35px;
        color: $dark-blue;
        border-radius: 6.45px;
        padding: 0 8px;
        background-color: #fff;

        @include screen('mobile') {
          height: 30px;
          font-size: 15px;
          line-height: 30px;
          font-weight: 500;
        }
      }
    }
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 30px;

    @include screen('small-desktop') {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include screen('tablet') {
      grid-template-columns: 1fr 1fr;
    }

    @include screen('mobile') {
      grid-template-columns: 1fr;
      grid-row-gap: 16px;
    }

    &.no-results {
      display: flex;
      justify-content: center;
    }
  }

  .empty-item {
    width: 359px;
    height: 333px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 16px 15px;

    @include screen('ultra-small-mobile') {
      width: 302px;
      padding: 15px 12px 10px 10px;
    }

    button {
      height: 208px;
      width: 100%;
      background-color: $black;
      margin-bottom: 21px;
      cursor: pointer;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      color: #fff;
      border: 1px solid #fff;
      font-family: $medium-font;
      font-size: 18px;
      row-gap: 10px;
      transition: opacity 0.2s ease;

      span {
        &:first-child {
          font-size: 24px;
          line-height: 0.7;
          margin-top: -9px;
        }
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .row {
      height: 12px;
      background-color: $black;
      margin-bottom: 15px;
      width: 100%;

      &:nth-child(1) {
        width: 92%;
      }

      &:nth-child(2) {
        width: 81%;
      }

      &:last-child {
        margin-bottom: 0;
        width: 87%;
      }
    }
  }

  .page-switcher {
    margin-top: 32px;

    @include screen('mobile') {
      margin-top: 54px;
    }

    ul {
      direction: ltr;

      li {
        &:first-child,
        &:last-child {
          transform: rotateY(180deg);
        }

        button {
          font-family: $medium-font;
          color: #fff;
          font-size: 21px;

          @include screen('mobile') {
            padding: 0;
          }
        }

        svg {
          fill: #fff;
          height: 52px;
          width: 52px;

          @include screen('mobile') {
            height: 52px;
            width: 52px;
          }
        }
      }
    }
  }
}
