@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.sociallsWrapper {
  position: absolute;
  left: 0;
  top: -4px;

  &.forMain {
    .sharesMenu {
      flex-direction: column;
      align-items: center;
      top: 36px;
      left: 0;
      width: 32px !important;
      height: 0;
      padding: 0;
      border-radius: 2px;
      background-color: rgba($color: #fff, $alpha: 0.5);
      .shareLink {
        width: 28px;
        height: 28px;
        min-width: 28px;
        min-height: 28px;
        margin-left: 0;
        margin-top: 9.5px;
        &:first-child {
          margin-right: 0;
        }
      }
      &.active {
        height: 116.5px;
      }
    }
  }

  .sharesMenuBtn {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    overflow: hidden;

    button {
      display: flex;
      width: 100%;
      padding: 0;
      cursor: pointer;
      i {
        display: block;
        width: 100%;
        svg {
          display: block;
          width: 100% !important;
        }
      }
    }
  }

  .sharesMenu {
    position: absolute;
    left: 32px;
    top: 0;
    width: 0;
    height: 100%;
    padding: 2px 0px;
    display: flex;
    transition: all ease 0.3s;
    background-color: #fff;
    overflow: hidden;
    cursor: pointer;
    &.active {
      width: 114.5px;
    }

    .shareLink {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      min-width: 28px;
      padding: 0;
      background-color: #2a323c;
      margin-left: 9.5px;
      border-radius: 2px;
      cursor: pointer;

      &:last-child {
        // margin-left: 2px;
        // margin-right: 2px;
      }
      &:first-child {
        margin-right: 2px;
      }
    }
  }
}
