@import './theme.scss';

html {
  font-size: 62.5%;

  //  Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  // Font variant
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;

  // Smoothing
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

html,
body {
  direction: rtl;
  padding: 0;
  margin: 0;
  font-family: $regular-font;
  background-color: $black;
  overflow-x: hidden;
  &.full-height {
    height: 100%;
    overflow-y: hidden;
  }
}

a {
  text-decoration: none;
  color: unset;
}

h1,
h2 {
  font-weight: normal;
}

button {
  outline: none;
  border: none;
}

p {
  margin: 0;
}
