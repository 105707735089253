@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.top-section {
  position: relative;
  margin-top: 58px;
  height: 530px;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin-bottom: 57px;

  &.no-results {
    margin-bottom: 51px;
  }

  @include screen('mobile') {
    height: initial;
    margin-top: 0;
    background-size: contain;
    background-position: 0 0;
    margin-bottom: 18.3vw;
  }

  @include screen('small-mobile') {
    margin-top: 58px;
  }

  .photo {
    border-radius: 14px;
    border: 3px solid #fff;
    width: 227px;
    height: 227px;
    flex-shrink: 0;
    background-size: cover;
    background-position: 50% 50%;

    @include screen('mobile') {
      margin-bottom: 20px;
      width: 177px;
      height: 177px;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .details {
    @include screen('mobile') {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }

    .socials {
      padding-bottom: 20px;

      @include screen('mobile') {
        padding-bottom: 18px;
      }
    }

    .name {
      font-family: $medium-font;
      font-size: 50px;
      line-height: 0.89;
      padding-bottom: 10px;

      @include screen('mobile') {
        text-align: center;
        padding-bottom: 8px;
      }
    }

    .subtitle {
      font-family: $medium-font;
      font-size: 24px;
      line-height: 1.25;
      padding-bottom: 10px;

      @include screen('mobile') {
        text-align: center;
      }
    }

    .description {
      font-family: $regular-font;
      font-size: 18px;
      line-height: 1.39;
      padding-bottom: 20px;

      @include screen('mobile') {
        text-align: center;
      }
    }

    .to-form-btn {
      position: relative;
      background-color: transparent;
      box-shadow: 0 0 0 1px #fff;
      border-radius: 4px;
      font-family: $medium-font;
      font-size: 18px;
      line-height: 40px;
      color: #fff;
      padding: 0 39px 0 30px;
      cursor: pointer;

      transition: opacity 0.2s ease;

      &:hover {
        opacity: 0.8;
      }

      i {
        position: absolute;
        top: 50%;
        right: 22px;
        transform: translate(0, -50%) scale(1.3);
        margin-left: 5px;
      }
    }
  }

  .hero,
  .hero-details {
    display: flex;

    @include screen('mobile') {
      display: block;
    }
  }

  .hero {
    align-items: center;
    column-gap: 58px;
  }

  .hero-details {
    column-gap: 31px;

    @include screen('mobile') {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }
  }

  .info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 734px;

    @include screen('mobile') {
      position: relative;
      width: 100%;
      padding: 0 28px;
      top: initial;
      right: initial;
      left: initial;
      bottom: initial;
      transform: none;
      padding-top: 70.6vw;

      &.person-details {
        padding-top: 39.3vw;
      }
    }

    .logo-wrap {
      position: relative;

      @include screen('mobile') {
        margin-bottom: 18px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -29px;
        height: 100%;
        width: 2px;
        background-color: rgba(255, 255, 255, 0.3);

        @include screen('mobile') {
          display: none;
        }
      }
    }

    .logo {
      display: block;
      width: 245px;
    }

    .text {
      position: relative;
      font-family: $regular-font;
      font-size: 21px;
      line-height: 30px;
      color: #fff;

      @include screen('mobile') {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}
