@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.socials {
  display: flex;
  column-gap: 18px;

  @include screen('mobile') {
    justify-content: center;
  }

  button {
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    cursor: pointer;
    background-color: #4d6177;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }
}
