@import 'src/styles/theme';
@import 'src/styles/mixins.scss';

.header {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 58px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::before {
    display: block;
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $black-secondary;
  }

  .n12-logo-wrap {
    position: relative;
    z-index: 1;
    height: 48px;
    margin: 0 24px 0 0;

    @include screen('mobile') {
      margin: 0 22px 0 0;
    }

    &.hide {
      @include screen('small-mobile') {
        display: none;
      }
    }

    img {
      height: 100%;
    }
  }

  .inner-logo {
    cursor: pointer;
    height: 42px;
  }

  button {
    position: relative;
    z-index: 1;
    padding: 2px 13px 0 15px;
    cursor: pointer;
    height: 29px;
    background-color: rgba(255, 255, 255, 1);
    color: $black;
    font-family: $medium-font;
    border-radius: 3px;
    font-size: 14px;
    display: flex;
    align-items: center;
    transition: opacity 0.2s ease-in;

    &:hover {
      opacity: 0.9;

      @include screen('mobile') {
        opacity: 1;
      }
    }

    @include screen('mobile') {
      height: 29px;
      font-size: 14px;
      padding: 1.5px 15px 1px;
    }

    &.hide-text {
      .text {
        @include screen('mobile') {
          visibility: hidden;
        }
      }
    }

    i {
      width: 10px;
      margin-left: 3px;
      margin-bottom: 1px;
    }
  }

  .left {
    position: absolute;
    top: 50%;
    left: 22px;
    transform: translate(0, -50%);
    display: flex;
    column-gap: 24px;

    .external-link,
    .back {
      font-family: $medium-font;
      font-size: 14px;
      text-decoration: underline;
      margin-top: 7px;
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 0.8;
      }
    }
    .back {
      color: #fff;
    }
  }

  .logo-wrapper {
    margin-left: 0;
    opacity: 0;
    display: flex;
    height: 28px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    visibility: hidden;
    transition: all 0.3s ease;

    &.show {
      opacity: 1;
      visibility: visible;
    }

    a {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
    }

    img {
      height: 100%;
    }
  }
}
