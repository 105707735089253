@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.filter-section {
  position: relative;
  width: 100%;
  height: auto;

  .content {
    width: 100%;
    max-width: 1475px;
    margin: 43px 0 0 0;
    position: relative;
    z-index: 2;

    @include screen('mobile') {
      margin: 29px 0 0 0;
    }

    .title {
      margin-right: -8px;
      font-family: $bold-font;
      font-size: 50px;
      line-height: 54px;
      display: flex;
      align-items: center;

      @include screen('mobile') {
        width: 100%;
        margin-right: 0;
        margin-top: 0px;
        margin-bottom: 17px;
        align-items: normal;
        font-size: 28px;
        line-height: 106%;
        text-align: center;
        padding-bottom: 6px;
        border-bottom: solid 3px #03337d;
      }

      .stripes {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 4.5px;
        margin-bottom: 2px;

        @include screen('mobile') {
          row-gap: 3.5px;
          margin-top: 5px;
          margin-left: -11px;
        }

        div {
          width: 37px;
          height: 8px;
          background-color: $dark-blue;

          @include screen('mobile') {
            width: 27px;
            height: 6.3px;
          }
        }
      }

      span {
        margin-right: 9px;

        @include screen('mobile') {
          margin-right: 0px;
        }
      }
    }

    form {
      @include screen('mobile') {
        width: 100%;
      }

      @include screen('ultra-small-mobile') {
        width: 302px;
      }
    }

    .subtitle {
      margin-top: 14px;
      margin-bottom: 21px;
      line-height: 21px;
      font-size: 18px;
      width: 376px;
      text-align: center;

      @include screen('small-mobile') {
        width: 314px;
      }
    }
  }
}
