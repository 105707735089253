@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.list-item {
  position: relative;
  width: 359px;
  padding: 16px 15px 15px 15px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #03337d;

  @include screen('mobile') {
    height: fit-content;
  }

  @include screen('ultra-small-mobile') {
    width: 302px;
    padding: 15px 12px 10px 10px;
  }

  .headerSocialls {
    top: 115px;
    left: 20px;
    z-index: 2;
  }

  .header {
    width: 100%;
    color: $dark-blue;
    font-size: 31px;
    line-height: 32px;
    font-family: $bold-font;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 18px;

    @include screen('ultra-small-mobile') {
      width: 262px;
      font-size: 26px;
      line-height: 23px;
    }

    .person-img {
      width: 70px;
      height: 70px;
      border-radius: 6px;
      border: 2px solid gray;
      object-fit: cover;
      cursor: pointer;
    }

    .person-name {
      position: relative;
      color: $black;
      font-size: 30px;
      font-family: $medium-font;
      line-height: 0.867;
      padding-bottom: 12px;
      padding-left: 20px;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        bottom: 4px;
        right: 0;
        width: 48px;
        height: 3px;
        background-color: #8f99b0;
      }
    }

    .sender {
      font-family: $light-font;
      font-size: 18px;
      color: $black;
      line-height: 23.4px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
    }
  }

  .photo {
    width: 329px;
    height: 207px;
    margin-bottom: 19px;
    position: relative;

    &.link {
      cursor: pointer;
    }

    @include screen('ultra-small-mobile') {
      margin-top: 16px;
      width: 276px;
      height: 174px;
    }

    .image-credit {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      @include screen('ultra-small-mobile') {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
      cursor: pointer;

      @include screen('ultra-small-mobile') {
        border-radius: 8px;
      }
    }
  }

  .sender-info {
    position: relative;
    font-family: $bold-font;
    color: $black;
    margin-bottom: 22.5px;
    padding-left: 35px;
    font-size: 18px;
    line-height: 23.4px;

    @include screen('ultra-small-mobile') {
      font-size: 15px;
      line-height: 19.5px;
    }
  }

  .subtitle {
    overflow: hidden;
    font-family: $regular-font;
    display: -webkit-box;
    height: auto;
    color: $black;
    font-size: 18px;
    line-height: 23.4px;

    @include screen('ultra-small-mobile') {
      margin-top: 15px;
      height: 39px;
      font-size: 15px;
      line-height: 19.5px;
    }
  }
}
