@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.footer {
  position: relative;
  z-index: 2;
  width: 100vw;
  padding: 53px 0 54px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: $grey-light;

  @include screen('mobile') {
    padding: 44px 0 28px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logos {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 32px;

      img {
        height: 26px;
      }
    }

    .text {
      font-size: 14px;
      line-height: 17px;
      font-family: $light-font;
      color: $black;
      width: 491px;

      @include screen('mobile') {
        width: 100%;
        max-width: 448px;
        font-size: 14px;
        line-height: 18px;
      }

      @include screen('small-mobile') {
        width: 100%;
        max-width: 448px;
      }

      div {
        width: 100%;
        text-align: center;
      }

      .title {
        font-family: $regular-font;
      }

      .top {
        @include screen('small-mobile') {
          // align-self: center;
        }
      }

      .bottom {
        span {
          a {
            text-decoration: underline;
          }
        }
      }
    }

    .socials {
      padding-top: 32px;
      width: 100%;
      display: flex;
      justify-content: center;
      column-gap: 21px;

      @include screen('mobile') {
        padding-top: 28px;
      }

      .social-btn {
        width: 28px;
        height: 28px;
        border-radius: 3px;
        background-color: #181f29;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: opacity 0.2s ease;

        &:hover {
          opacity: 0.8;
        }
      }

      div {
        & i {
          cursor: pointer;
          width: 28px;
          opacity: 0.8;
          transition: all 0.3s ease;

          @include screen('mobile') {
            width: vw(28);
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .rabbi-credit {
      padding-top: 20px;
      text-align: center;
      font-size: 12px;
      line-height: 14px;
      color: #000;
      a {
        color: #000;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
