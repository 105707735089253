@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@font-face {
  font-family: "Yonit-Light";
  src: url("./fonts/Yonit-Light.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Yonit-Regular";
  src: url("./fonts/Yonit-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Yonit-Medium";
  src: url("./fonts/Yonit-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Yonit-Bold";
  src: url("./fonts/Yonit-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}
html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

html,
body {
  direction: rtl;
  padding: 0;
  margin: 0;
  font-family: "Yonit-Regular", sans-serif;
  background-color: #0e151d;
  overflow-x: hidden;
}
html.full-height,
body.full-height {
  height: 100%;
  overflow-y: hidden;
}

a {
  text-decoration: none;
  color: unset;
}

h1,
h2 {
  font-weight: normal;
}

button {
  outline: none;
  border: none;
}

p {
  margin: 0;
}